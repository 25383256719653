$(document).ready(function(){


    if($('.carousel').length){
        $('.carousel').carousel();
    }

    if($('.sendContato').length){
        $('.rtnEmail').hide();
        $('.sendContato').on('submit', function(){
            var action  = $(this).attr('action');
            var method  = $(this).attr('method')
            var form  = $(this);

            $('.rtnEmail').html('<i class="fa fa-spinner fa-pulse"></i> Enviando...').show();

            $.ajax({
                url: action,
                type: method,
                dataType: 'json',
                data: form.serialize(),
                error: function() {
                    $('.rtnEmail').hide();
                    alert('Error: Falha: tente novamente ou contate o administrador!');
                },
                success: function(data) {
                    $('.rtnEmail').addClass(data.return);
                    $('.rtnEmail').html(data.message);
                    form.get(0).reset();
                }
            });
            return false;
        });
    }
});

(function($) {
    
    "use strict";
    
    //Hide Loading Box (Preloader)
    function handlePreloader() {
        if($('.preloader').length){
            $('.preloader').delay(200).fadeOut(500);
        }
    }
    
    //Update Header Style and Scroll to Top
    function headerStyle() {
        if($('.main-header').length){
            var windowpos = $(window).scrollTop();
            var siteHeader = $('.main-header');
            var sticky_Header = $('.sticky-header');
            var scrollLink = $('.scroll-to-top');
            if (windowpos >= 300) {
                sticky_Header.addClass("animated slideInDown")
                siteHeader.addClass('fixed-header');
                scrollLink.fadeIn(300);
            } else {
                sticky_Header.removeClass("animated slideInDown")
                siteHeader.removeClass('fixed-header');
                scrollLink.fadeOut(300);
            }
        }
    }

    headerStyle();
    
    //Submenu Dropdown Toggle
    if($('.main-header .navigation li.dropdown ul').length){
        $('.main-header .navigation li.dropdown').append('<div class="dropdown-btn"><span class="fa fa-angle-down"></span></div>');
        
        //Dropdown Button
        $('.main-header .navigation li.dropdown .dropdown-btn').on('click', function() {
            $(this).prev('ul').slideToggle(500);
        });
        
        //Disable dropdown parent link
        $('.navigation li.dropdown > a').on('click', function(e) {
            e.preventDefault();
        });
    }
    
    // Features Carousel
    if ($('.features-carousel').length) {
        $('.features-carousel').owlCarousel({
            loop:true,
            margin:30,
            nav:true,
            smartSpeed: 700,
            autoplay: false,
            navText: [ '<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>' ],
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:2
                },
                800:{
                    items:3
                },
                1200:{
                    items:4
                }
            }
        });         
    }


    // News Carousel
    if ($('.news-carousel').length) {
        $('.news-carousel').owlCarousel({
            loop:true,
            margin:30,
            nav:true,
            smartSpeed: 700,
            autoplay: false,
            navText: [ '<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>' ],
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:1
                },
                768:{
                    items:2
                },
                1200:{
                    items:2
                }
            }
        });         
    }


    // Testimonial Carousel
    if ($('.testimonial-carousel').length) {
        $('.testimonial-carousel').owlCarousel({
            loop:true,
            margin:100,
            nav:true,
            smartSpeed: 500,
            autoplay: true,
            navText: [ '<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>' ],
            responsive:{
                0:{
                    items:1
                },
                800:{
                    items:1
                },
                1024:{
                    items:2,
                    margin:50
                },
                1200:{
                    items:2
                }
            }
        });         
    }


    // Testimonial Carousel Two
    if ($('.testimonial-carousel-two').length) {
        $('.testimonial-carousel-two').owlCarousel({
            loop:true,
            margin:100,
            nav:true,
            smartSpeed: 500,
            autoplay: true,
            navText: [ '<span class="fa fa-long-arrow-alt-left"></span>', '<span class="fa fa-long-arrow-alt-right"></span>' ],
            responsive:{
                0:{
                    items:1
                },
                800:{
                    items:1
                },
                1200:{
                    items:1
                }
            }
        });         
    }


    // Testimonial Carousel Three
    if ($('.testimonial-carousel-three').length) {
        $('.testimonial-carousel-three').owlCarousel({
            loop:true,
            margin:0,
            nav:true,
            smartSpeed: 500,
            autoplay: true,
            navText: [ '<span class="fa fa-long-arrow-alt-left"></span>', '<span class="fa fa-long-arrow-alt-right"></span>' ],
            responsive:{
                0:{
                    items:1
                },
                800:{
                    items:1
                },
                1200:{
                    items:1
                }
            }
        });         
    }


    // Sponsors Carousel
    if ($('.sponsors-carousel').length) {
        $('.sponsors-carousel').owlCarousel({
            loop:true,
            margin:30,
            nav:true,
            smartSpeed: 500,
            autoplay: true,
            navText: [ '<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>' ],
            responsive:{
                0:{
                    items:1
                },
                480:{
                    items:2
                },
                800:{
                    items:3
                },
                1024:{
                    items:4
                },              
                1200:{
                    items:5
                }

            }
        });         
    }


    // Services Carousel
    if ($('.services-carousel').length) {
        $('.services-carousel').owlCarousel({
            loop:true,
            margin:30,
            nav:true,
            smartSpeed: 500,
            autoplay: 4000,
            navText: [ '<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>' ],
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:1 
                },
                768:{
                    items:2
                },
                1024:{
                    items:3
                }
            }
        });         
    }


    // Service Detail Slider
    if ($('.service-detail-slider').length) {
        $('.service-detail-slider').owlCarousel({
            loop:true,
            margin:30,
            nav:true,
            smartSpeed: 500,
            autoplay: 4000,
            navText: [ '<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>' ],
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:1 
                },
                1024:{
                    items:1
                }
            }
        });         
    }


    // Product Carousel
    if ($('.project-carousel').length) {
        $('.project-carousel').owlCarousel({
            loop:true,
            margin:6,
            nav:false,
            smartSpeed: 700,
            autoplay: 5000,
            navText: [ '<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>' ],
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:2
                },
                800:{
                    items:3
                },
                1024:{
                    items:4
                },
                1400:{
                    items:5
                },
                1600:{
                    items:6
                }
            }
        });
    }


    //Fact Counter + Text Count
    if($('.count-box').length){
        $('.count-box').appear(function(){
    
            var $t = $(this),
                n = $t.find(".count-text").attr("data-stop"),
                r = parseInt($t.find(".count-text").attr("data-speed"), 10);
                
            if (!$t.hasClass("counted")) {
                $t.addClass("counted");
                $({
                    countNum: $t.find(".count-text").text()
                }).animate({
                    countNum: n
                }, {
                    duration: r,
                    easing: "linear",
                    step: function() {
                        $t.find(".count-text").text(Math.floor(this.countNum));
                    },
                    complete: function() {
                        $t.find(".count-text").text(this.countNum);
                    }
                });
            }
            
        },{accY: 0});
    }


    if($('.timer').length){
       $(function(){
            $('[data-countdown]').each(function() {
           var $this = $(this), finalDate = $(this).data('countdown');
           $this.countdown(finalDate, function(event) {
             $this.html(event.strftime('%D days %H:%M:%S'));
           });
         });
        });

       $('.cs-countdown').countdown('').on('update.countdown', function(event) {
          var $this = $(this).html(event.strftime('<div><span>%D</span><h6>day</h6></div> <div><span>%H</span><h6>hrs</h6></div> <div><span>%M</span><h6>min</h6></div> <div><span>%S</span><h6>sec</h6></div>'));
        });
    }

    //Accordion Box
    if($('.accordion-box').length){
        $(".accordion-box").on('click', '.acc-btn', function() {
            
            var outerBox = $(this).parents('.accordion-box');
            var target = $(this).parents('.accordion');
            
            if($(this).hasClass('active')!==true){
                $(outerBox).find('.accordion .acc-btn').removeClass('active');
            }
            
            if ($(this).next('.acc-content').is(':visible')){
                return false;
            }else{
                $(this).addClass('active');
                $(outerBox).children('.accordion').removeClass('active-block');
                $(outerBox).find('.accordion').children('.acc-content').slideUp(300);
                target.addClass('active-block');
                $(this).next('.acc-content').slideDown(300);    
            }
        }); 
    }

    //Tabs Box
    if($('.tabs-box').length){
        $('.tabs-box .tab-buttons .tab-btn').on('click', function(e) {
            e.preventDefault();
            var target = $($(this).attr('data-tab'));
            
            if ($(target).is(':visible')){
                return false;
            }else{
                target.parents('.tabs-box').find('.tab-buttons').find('.tab-btn').removeClass('active-btn');
                $(this).addClass('active-btn');
                target.parents('.tabs-box').find('.tabs-content').find('.tab').fadeOut(0);
                target.parents('.tabs-box').find('.tabs-content').find('.tab').removeClass('active-tab');
                $(target).fadeIn(300);
                $(target).addClass('active-tab');
            }
        });
    }

    //Price Range Slider
    if($('.vehicle-year-slider').length){
        $( ".vehicle-year-slider" ).slider({
            range: true,
            min: 0,
            max: 3000,
            values: [ 100, 2025 ],
            slide: function( event, ui ) {
            $( "input.property-amount" ).val( ui.values[ 1 ] );
            }
        });
        
        $( "input.property-amount" ).val( $( ".vehicle-year-slider" ).slider( "values", 1 ) );  
    }

    //Price Range Slider
    if($('.price-range-slider').length){
        $( ".price-range-slider" ).slider({
            range: true,
            min: 0,
            max: 90,
            values: [ 8, 85 ],
            slide: function( event, ui ) {
            $( "input.property-amount" ).val( ui.values[ 0 ] + " - " + ui.values[ 1 ] );
            }
        });
        
        $( "input.property-amount" ).val( $( ".price-range-slider" ).slider( "values", 0 ) + " - $" + $( ".price-range-slider" ).slider( "values", 1 ) );   
    }

    //Custom Seclect Box
    if($('.custom-select-box').length){
        $('.custom-select-box').selectmenu().selectmenu('menuWidget').addClass('overflow');
    }
    
    //Gallery Filters
     if($('.filter-list').length){
         $('.filter-list').mixItUp({});
     }

     //Jquery Spinner / Quantity Spinner
    if($('.quantity-spinner').length){
        $("input.quantity-spinner").TouchSpin({
          verticalbuttons: true
        });
    }


    //LightBox / Fancybox
    if($('.lightbox-image').length) {
        $('.lightbox-image').fancybox({
            openEffect  : 'fade',
            closeEffect : 'fade',
            helpers : {
                media : {}
            }
        });
    }
    
    
    //Contact Form Validation
    // if($('#contact-form').length){
    //     $('#contact-form').validate({
    //         rules: {
    //             username: {
    //                 required: true
    //             },
    //             email: {
    //                 required: true,
    //                 email: true
    //             },
    //             subject: {
    //                 required: true
    //             },
    //             phone: {
    //                 required: true
    //             },
    //             message: {
    //                 required: true
    //             }
    //         }
    //     });
    // }
    
    
    // Scroll to a Specific Div
    if($('.scroll-to-target').length){
        $(".scroll-to-target").on('click', function() {
            var target = $(this).attr('data-target');
           // animate
           $('html, body').animate({
               scrollTop: $(target).offset().top
             }, 1000);
    
        });
    }
    
    
    // Elements Animation
    if($('.wow').length){
        var wow = new WOW(
          {
            boxClass:     'wow',      // animated element css class (default is wow)
            animateClass: 'animated', // animation css class (default is animated)
            offset:       0,          // distance to the element when triggering the animation (default is 0)
            mobile:       false,       // trigger animations on mobile devices (default is true)
            live:         true       // act on asynchronously loaded content (default is true)
          }
        );
        wow.init();
    }

/* ==========================================================================
   When document is Scrollig, do
   ========================================================================== */
    
    $(window).on('scroll', function() {
        headerStyle();
    });
    
/* ==========================================================================
   When document is loaded, do
   ========================================================================== */
    
    $(window).on('load', function() {
        handlePreloader();
    }); 

})(window.jQuery);


/* --------------------------------------------
Google Map
-------------------------------------------- */ 
window.onload = MapLoadScript;
function GmapInit() {
      Gmap = $('.map-canvas');
      Gmap.each(function() {
        var $this           = $(this),
            lat             = '',
            lng             = '',
            zoom            = 12,
            scrollwheel     = false,
            zoomcontrol     = true,
            draggable       = true,
            mapType         = google.maps.MapTypeId.ROADMAP,
            title           = '',
            contentString   = '',
            theme_icon_path         = $this.data('icon-path'),
            dataLat         = $this.data('lat'),
            dataLng         = $this.data('lng'),
            dataZoom        = $this.data('zoom'),
            dataType        = $this.data('type'),
            dataScrollwheel = $this.data('scrollwheel'),
            dataZoomcontrol = $this.data('zoomcontrol'),
            dataHue         = $this.data('hue'),
            dataTitle       = $this.data('title'),
            dataContent     = $this.data('content');
            
        if( dataZoom !== undefined && dataZoom !== false ) {
            zoom = parseFloat(dataZoom);
        }
        if( dataLat !== undefined && dataLat !== false ) {
            lat = parseFloat(dataLat);
        }
        if( dataLng !== undefined && dataLng !== false ) {
            lng = parseFloat(dataLng);
        }
        if( dataScrollwheel !== undefined && dataScrollwheel !== null ) {
            scrollwheel = dataScrollwheel;
        }
        if( dataZoomcontrol !== undefined && dataZoomcontrol !== null ) {
            zoomcontrol = dataZoomcontrol;
        }
        if( dataType !== undefined && dataType !== false ) {
            if( dataType == 'satellite' ) {
                mapType = google.maps.MapTypeId.SATELLITE;
            } else if( dataType == 'hybrid' ) {
                mapType = google.maps.MapTypeId.HYBRID;
            } else if( dataType == 'terrain' ) {
                mapType = google.maps.MapTypeId.TERRAIN;
            }           
        }
        if( dataTitle !== undefined && dataTitle !== false ) {
            title = dataTitle;
        }
        if( navigator.userAgent.match(/iPad|iPhone|Android/i) ) {
            draggable = false;
        }
        
        var mapOptions = {
          zoom        : zoom,
          scrollwheel : scrollwheel,
          zoomControl : zoomcontrol,
          draggable   : draggable,
          center      : new google.maps.LatLng(lat, lng),
          mapTypeId   : mapType
        };      
        var map = new google.maps.Map($this[0], mapOptions);
        
        //var image = 'images/icons/map-marker.png';
        var image = theme_icon_path;
        
        if( dataContent !== undefined && dataContent !== false ) {
            contentString = '<div class="map-data">' + '<h6>' + title + '</h6>' + '<div class="map-content">' + dataContent + '</div>' + '</div>';
        }
        var infowindow = new google.maps.InfoWindow({
            content: contentString
        });
        
        var marker = new google.maps.Marker({
          position : new google.maps.LatLng(lat, lng),
          map      : map,
          icon     : image,
          title    : title
        });
        if( dataContent !== undefined && dataContent !== false ) {
            google.maps.event.addListener(marker, 'click', function() {
                infowindow.open(map,marker);
            });
        }
        
        if( dataHue !== undefined && dataHue !== false ) {
          var styles = [
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#444444"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#fafafa"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 45
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#a0a09a"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                }
            ];
          map.setOptions({styles: styles});
        }
     });
}
        
    function MapLoadScript() {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        GmapInit();
        document.body.appendChild(script);
    }

function removeCot(rowID){
    $('#row' + rowID).fadeOut(1000, function() { 
        $(this).remove(); 
    });
}

function addItemCot(){
    totalCot = parseInt($('#totalcot').val())+1;
    $('#totalcot').val(totalCot);

    var html = '<tr id="row' + totalCot + '">';
    html += '       <td><input type="number" value="" placeholder="0" name="quantidade[]" required></td>';
    html += '       <td>';
    html += '           <select name="unidade[]" class="form-control" required>';
    html += '               <option value="">Selecione</option>';
    html += '               <option value="litros">Litros</option>';
    html += '               <option value="peca">Peça</option>';
    html += '               <option value="kg">Quilos</option>';
    html += '               <option value="m">Metro</option>';
    html += '               <option value="galão">Galão</option>';
    html += '               <option value="lata">lata</option>';
    html += '               <option value="kit">Kit</option>';
    html += '               <option value="barrica">Barrica</option>';
    html += '               <option value="frasco">Frasco</option>';
    html += '               <option value="balde">Balde</option>';
    html += '               <option value="tubo">Tubo</option>';
    html += '           </select>';
    html += '       </td>';
    html += '       <td><input type="text" value="" placeholder="Marca" name="marca[]" required></td>';
    html += '       <td><input type="text" value="" placeholder="Descrição do Produto" maxlength="255" name="descricao[]" required></td>';
    html += '       <td><input type="text" value="" placeholder="Volume" name="valume[]" required></td>';
    html += '       <td><a href="javascript:void(0);" class="remove-btn" onclick="removeCot(' + totalCot + ');"><span class="fa fa-times"></span></a></td>';
    html += '   </tr>';

    $('.cart-table tbody').append(html);
}